import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-633932ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_table = _resolveComponent("b-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    full: "",
    title: _ctx.pageTitle,
    "go-back": _ctx.goBack
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_table, {
          items: _ctx.state.results,
          fields: _ctx.columnDefinition,
          loading: _ctx.state.loading,
          "sticky-header": "calc(100vh - 105px)"
        }, null, 8, ["items", "fields", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}