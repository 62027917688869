import Axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import HistoryDTO from '@/dtos/HistoryDTO';
import EntityType from '@/domain/enums/EntityTypes';

export default class ChangeLogApiService {
    public async getHistory(entityType: EntityType, entityId: number): Promise<DataAccessResponse<Array<HistoryDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<HistoryDTO>>> = await Axios({
            method: 'get',
            url: `${API_SERVICE_URL}/ChangeLog/type/${entityType}/id/${entityId}`,
        });
        return new DataListAccessResponseClass<HistoryDTO>(response, HistoryDTO).response;
    }
}
