
import {
    computed, defineComponent, onMounted, reactive,
} from 'vue';
import ChangeLogService from '@/services/ChangeLogService';
import Screen from '@/components/layout/Screen.vue';
import { ColumnDefinition } from '@/types';
import HistoryDTO from '@/dtos/HistoryDTO';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    loading: boolean;
    results: Array<HistoryDTO>;
};

export default defineComponent({
    name: 'master-data-history-list',
    components: {
        Screen,
    },
    props: {
        entityType: { type: String, required: true },
        entityId: { type: String, required: true },
    },
    setup(props) {
        const changeLogService = new ChangeLogService();

        const entityName = EntityType[+props.entityType];
        const pageTitle = computed(() => (
            `${getTitleCaseTranslation('core.common.historyFor')} ${getTitleCaseTranslation(`core.domain.${useStringFormatter().camelCase(entityName)}`)} ${props.entityId}`
        ));
        const state = reactive<State>({
            loading: true,
            results: [],
        });

        const columnDefinition: Array<ColumnDefinition<HistoryDTO>> = [
            {
                label: getTitleCaseTranslation('core.domain.dateAndTime'),
                key: 'normalizedDate',
                searchable: true,
                width: '20%',
            },
            {
                label: getTitleCaseTranslation('core.domain.user'),
                key: 'username',
                searchable: true,
                width: '20%',
            },
            {
                label: getTitleCaseTranslation('core.domain.change'),
                key: 'message',
                ignoreSort: true,
                searchable: true,
                width: '60%',
            },
        ];

        function goBack() {
            router.go(-1);
        }

        onMounted(async () => {
            const result = await changeLogService.getHistory(+props.entityType, +props.entityId);
            if (result.success) {
                state.results = result.history;
            }
            state.loading = false;
        });

        return {
            state,
            columnDefinition,
            goBack,
            pageTitle,
        };
    },
});
