import { normalizeDate, formatDateTimeUI } from '@/functions/date';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class HistoryDTO {
    constructor(init?: Partial<HistoryDTO>) {
        Object.assign(this, init);
    }

    public username!: string;

    public changeDate!: string;

    public fieldName!: string;

    public previousValue!: string;

    public newValue!: string;

    public get message(): string {
        if (this.previousValue === null || this.previousValue === undefined) {
            return `${getTitleCaseTranslation('core.common.added')} ${this.fieldName} \`${this.newValue}\`.`;
        }
        if (this.newValue === null || this.newValue === undefined) {
            return `${getTitleCaseTranslation('core.common.removed')} ${this.fieldName} \`${this.previousValue}\`.`;
        }
        return getTitleCaseTranslation('core.common.changedFromTo', this.fieldName, this.previousValue, this.newValue);
    }

    public get normalizedDate(): string {
        return formatDateTimeUI(normalizeDate(this.changeDate));
    }
}
