import HistoryDTO from '@/dtos/HistoryDTO';
import EntityType from '@/domain/enums/EntityTypes';
import ChangeLogApiService from './api/ChangeLogApiService';

export default class ChangeLogService {
    private apiService: ChangeLogApiService;

    constructor() {
        this.apiService = new ChangeLogApiService();
    }

    public async getHistory(entityType: EntityType, entityId: number): Promise<{ history: Array<HistoryDTO>; success: boolean }> {
        const response = await this.apiService.getHistory(entityType, entityId);
        return {
            history: response.data,
            success: response.success,
        };
    }
}
